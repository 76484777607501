@import 'app/variables';
@import '~bootstrap/scss/bootstrap';

/*
 * override bootstrap
 */
.form-check-right {

  @extend .form-check;
  padding-left: unset;
  padding-right: 1.5em;

  .form-check-input-right {
    @extend .form-check-input;
    float: right;
    margin-left: .75em;
    margin-right: -1em;
  }
}

.btn:disabled {
  cursor: not-allowed;
  pointer-events: auto !important;
}

.btn:focus {
  box-shadow: none;
}

.table > :not(:first-child) {
  border-top: 0;
}

/*
 * elements
 */
html, body, #root {
  height: 100%;
  font-size: 14px;
}

label {
  margin-bottom: 0;
}

table.centered-columns th,
table.centered-columns td {
  text-align: center;
}

/*
 * classes
 */
.border-sides {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.lineup-column {

  @extend .border-end;

  &:last-child {
    border: none !important;
  }
}

.link {
  cursor: pointer;
  color: #013399;

  &:hover {
    text-decoration: underline;
  }
}

.modal-dialog.data-modal {
  width: 60%;
  max-width: none !important;

  min-height: 60%;

  .modal-content {
    .modal-header {
      button {
        align-self: flex-start;
      }
    }

    .modal-body {
      min-height: 450px;
      max-height: calc(100vh - 258px);
      overflow-y: auto;
    }
  }
}

.modal-dialog.message-modal {
  width: 35%;
  max-width: none !important;

  .modal-content {
    .modal-header {
      button {
        align-self: flex-start;
      }
    }

    .modal-body {
      min-height: 200px;
      overflow-y: auto;
    }
  }
}

.negative-dollars {
  color: #f00;
}

.pointer {
  cursor: pointer;
}

.status-indication {
  color: #f00;
  font-weight: 500;
}

.table td.w-content,
.table th.w-content {
  white-space: nowrap;
  width: 1%;
}

.text-input {
  border-color: #eee;
  background-color: #f7f7f7;
}

/*
 * not used

.scrollable-table-container {
  overflow-y: auto;
  height: 455px;
  border-bottom: 1px solid var(--bs-border-color);
}

.scrollable-table-container thead th {
  position: sticky;
  top: -1px;
}

 */
