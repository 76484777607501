@import 'variables';

.auth-form {
  .auth-input,
  .submit-button
  {
    width: $auth-element-width;
  }

  .login-failed
  {
    height: 2rem;
    width: $auth-element-width;
  }
}
